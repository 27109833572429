/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TranscarentMemberAccessRequest = {
    /**
     * Transcarent member's id
     */
    transcarentMemberId: string;
    /**
     * User's email
     */
    email: string;
    /**
     * User's state
     */
    state: string;
    /**
     * User's city
     */
    city: string;
    /**
     * User's zip
     */
    zip: string;
    /**
     * User's first name
     */
    firstName: string;
    /**
     * User's last name
     */
    lastName: string;
    /**
     * User's dateOfBirth
     */
    dateOfBirth: string;
    /**
     * User's preferredLanguage
     */
    preferredLanguage: string;
    /**
     * User's phoneNumber
     */
    phoneNumber: string;
    /**
     * User's gender
     */
    gender: TranscarentMemberAccessRequest.gender;
    /**
     * User's street
     */
    address1: string;
    /**
     * User's aptSuite
     */
    address2?: string;
};

export namespace TranscarentMemberAccessRequest {

    /**
     * User's gender
     */
    export enum gender {
        MALE = 'MALE',
        FEMALE = 'FEMALE',
        UNDEFINED = 'UNDEFINED',
    }


}

